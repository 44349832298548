/*
 * ⚠ This file is autogenerated ⚠
 */

export declare type TypographyStyle = {
  fontSize: number | [number, number, number];
  textDecoration: string | [string, string, string];
  fontWeight: number | [number, number, number];
  letterSpacing: number | [number, number, number];
  lineHeight: number | [number, number, number];
  paragraphIndent: number | [number, number, number];
  textCase: string | [string, string, string];
};

export const TypographyVariants = {
  title: [
    'xxlarge',
    'xlarge',
    'large',
    'medium',
    'small',
    'xsmall',
    'xxsmall',
  ],
  heading: [
    'xlargebold',
    'xlarge',
    'largebold',
    'large',
    'mediumbold',
    'medium',
    'smallbold',
    'small',
  ],
  paragraph: [
    'large',
    'largeunderline',
    'medium',
    'mediumunderline',
    'small',
    'smallunderline',
    'extrasmall',
    'extrasmallunderline',
  ],
  label: [
    'extraextralargebold',
    'extraextralarge',
    'extralargebold',
    'extralarge',
    'largebold',
    'large',
    'mediumbold',
    'medium',
    'smallbold',
    'small',
    'extrasmallbold',
    'extrasmall',
    'mediumnumeric',
    'smallnumeric',
    'largebutton',
    'largebuttonunderline',
    'smallbutton',
    'smallbuttonunderline',
  ],
  caption: [
    'large',
    'medium',
    'small',
    'strikethrough',
    'caps',
  ],
} as const;

export type TitleVariants = typeof TypographyVariants.title[number];
export type HeadingVariants = typeof TypographyVariants.heading[number];
export type ParagraphVariants = typeof TypographyVariants.paragraph[number];
export type LabelVariants = typeof TypographyVariants.label[number];
export type CaptionVariants = typeof TypographyVariants.caption[number];

export interface Typography {
  title: { xxlarge: TypographyStyle;
    xlarge: TypographyStyle;
    large: TypographyStyle;
    medium: TypographyStyle;
    small: TypographyStyle;
    xsmall: TypographyStyle;
    xxsmall: TypographyStyle; };
  heading: { xlargebold: TypographyStyle;
    xlarge: TypographyStyle;
    largebold: TypographyStyle;
    large: TypographyStyle;
    mediumbold: TypographyStyle;
    medium: TypographyStyle;
    smallbold: TypographyStyle;
    small: TypographyStyle; };
  paragraph: { large: TypographyStyle;
    largeunderline: TypographyStyle;
    medium: TypographyStyle;
    mediumunderline: TypographyStyle;
    small: TypographyStyle;
    smallunderline: TypographyStyle;
    extrasmall: TypographyStyle;
    extrasmallunderline: TypographyStyle; };
  label: { extraextralargebold: TypographyStyle;
    extraextralarge: TypographyStyle;
    extralargebold: TypographyStyle;
    extralarge: TypographyStyle;
    largebold: TypographyStyle;
    large: TypographyStyle;
    mediumbold: TypographyStyle;
    medium: TypographyStyle;
    smallbold: TypographyStyle;
    small: TypographyStyle;
    extrasmallbold: TypographyStyle;
    extrasmall: TypographyStyle;
    mediumnumeric: TypographyStyle;
    smallnumeric: TypographyStyle;
    largebutton: TypographyStyle;
    largebuttonunderline: TypographyStyle;
    smallbutton: TypographyStyle;
    smallbuttonunderline: TypographyStyle; };
  caption: { large: TypographyStyle;
    medium: TypographyStyle;
    small: TypographyStyle;
    strikethrough: TypographyStyle;
    caps: TypographyStyle; };
}

export const typography: Typography = {
  title: {
    xxlarge: {
      fontSize: [
        32,
        48,
        72,
      ],
      textDecoration: [
        'none',
        'none',
        'none',
      ],
      fontWeight: [
        700,
        700,
        700,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        40,
        56,
        80,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
    xlarge: {
      fontSize: [
        32,
        48,
        64,
      ],
      textDecoration: [
        'none',
        'none',
        'none',
      ],
      fontWeight: [
        700,
        700,
        700,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        40,
        56,
        72,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
    large: {
      fontSize: [
        32,
        40,
        56,
      ],
      textDecoration: [
        'none',
        'none',
        'none',
      ],
      fontWeight: [
        700,
        700,
        700,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        40,
        48,
        64,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
    medium: {
      fontSize: [
        32,
        40,
        48,
      ],
      textDecoration: [
        'none',
        'none',
        'none',
      ],
      fontWeight: [
        700,
        700,
        700,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        40,
        48,
        56,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
    small: {
      fontSize: [
        24,
        32,
        40,
      ],
      textDecoration: [
        'none',
        'none',
        'none',
      ],
      fontWeight: [
        700,
        700,
        700,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        32,
        40,
        48,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
    xsmall: {
      fontSize: [
        24,
        32,
        32,
      ],
      textDecoration: [
        'none',
        'none',
        'none',
      ],
      fontWeight: [
        700,
        700,
        700,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        32,
        40,
        40,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
    xxsmall: {
      fontSize: [
        24,
        24,
        24,
      ],
      textDecoration: [
        'none',
        'none',
        'none',
      ],
      fontWeight: [
        700,
        700,
        700,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        32,
        32,
        32,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
  },
  heading: {
    xlargebold: {
      fontSize: [
        32,
        32,
        32,
      ],
      textDecoration: [
        'none',
        'none',
        'none',
      ],
      fontWeight: [
        700,
        700,
        700,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        40,
        40,
        40,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
    xlarge: {
      fontSize: [
        32,
        32,
        32,
      ],
      textDecoration: [
        'none',
        'none',
        'none',
      ],
      fontWeight: [
        400,
        400,
        400,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        40,
        40,
        40,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
    largebold: {
      fontSize: 24,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 32,
      paragraphIndent: 0,
      textCase: 'none',
    },
    large: {
      fontSize: 24,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 32,
      paragraphIndent: 0,
      textCase: 'none',
    },
    mediumbold: {
      fontSize: 18,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    medium: {
      fontSize: 18,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    smallbold: {
      fontSize: 16,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    small: {
      fontSize: 16,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
  },
  paragraph: {
    large: {
      fontSize: [
        18,
        18,
        24,
      ],
      textDecoration: [
        'none',
        'none',
        'none',
      ],
      fontWeight: [
        400,
        400,
        400,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        28,
        28,
        40,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
    largeunderline: {
      fontSize: [
        18,
        18,
        24,
      ],
      textDecoration: [
        'underline',
        'underline',
        'underline',
      ],
      fontWeight: [
        400,
        400,
        400,
      ],
      letterSpacing: [
        0,
        0,
        0,
      ],
      lineHeight: [
        28,
        28,
        40,
      ],
      paragraphIndent: [
        0,
        0,
        0,
      ],
      textCase: [
        'none',
        'none',
        'none',
      ],
    },
    medium: {
      fontSize: 18,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 28,
      paragraphIndent: 0,
      textCase: 'none',
    },
    mediumunderline: {
      fontSize: 18,
      textDecoration: 'underline',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 28,
      paragraphIndent: 0,
      textCase: 'none',
    },
    small: {
      fontSize: 16,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    smallunderline: {
      fontSize: 16,
      textDecoration: 'underline',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    extrasmall: {
      fontSize: 14,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 20,
      paragraphIndent: 0,
      textCase: 'none',
    },
    extrasmallunderline: {
      fontSize: 14,
      textDecoration: 'underline',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 20,
      paragraphIndent: 0,
      textCase: 'none',
    },
  },
  label: {
    extraextralargebold: {
      fontSize: 32,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 40,
      paragraphIndent: 0,
      textCase: 'none',
    },
    extraextralarge: {
      fontSize: 32,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 40,
      paragraphIndent: 0,
      textCase: 'none',
    },
    extralargebold: {
      fontSize: 24,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 32,
      paragraphIndent: 0,
      textCase: 'none',
    },
    extralarge: {
      fontSize: 24,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 32,
      paragraphIndent: 0,
      textCase: 'none',
    },
    largebold: {
      fontSize: 18,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    large: {
      fontSize: 18,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    mediumbold: {
      fontSize: 16,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 20,
      paragraphIndent: 0,
      textCase: 'none',
    },
    medium: {
      fontSize: 16,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 20,
      paragraphIndent: 0,
      textCase: 'none',
    },
    smallbold: {
      fontSize: 14,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 16,
      paragraphIndent: 0,
      textCase: 'none',
    },
    small: {
      fontSize: 14,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 16,
      paragraphIndent: 0,
      textCase: 'none',
    },
    extrasmallbold: {
      fontSize: 12,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 16,
      paragraphIndent: 0,
      textCase: 'none',
    },
    extrasmall: {
      fontSize: 12,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 16,
      paragraphIndent: 0,
      textCase: 'none',
    },
    mediumnumeric: {
      fontSize: 16,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 10,
      lineHeight: 20,
      paragraphIndent: 0,
      textCase: 'none',
    },
    smallnumeric: {
      fontSize: 14,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 10,
      lineHeight: 20,
      paragraphIndent: 0,
      textCase: 'none',
    },
    largebutton: {
      fontSize: 18,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    largebuttonunderline: {
      fontSize: 18,
      textDecoration: 'underline',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    smallbutton: {
      fontSize: 14,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 20,
      paragraphIndent: 0,
      textCase: 'none',
    },
    smallbuttonunderline: {
      fontSize: 14,
      textDecoration: 'underline',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 20,
      paragraphIndent: 0,
      textCase: 'none',
    },
  },
  caption: {
    large: {
      fontSize: 20,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    medium: {
      fontSize: 16,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 24,
      paragraphIndent: 0,
      textCase: 'none',
    },
    small: {
      fontSize: 14,
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 18,
      paragraphIndent: 0,
      textCase: 'none',
    },
    strikethrough: {
      fontSize: 14,
      textDecoration: 'line-through',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 18,
      paragraphIndent: 0,
      textCase: 'none',
    },
    caps: {
      fontSize: 14,
      textDecoration: 'none',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 18,
      paragraphIndent: 0,
      textCase: 'uppercase',
    },
  },
};
