/*
 * ⚠ This file is autogenerated ⚠
 */

export type Radius = number;

export interface Radii {

  '2': Radius;

  '4': Radius;

  '8': Radius;

  '12': Radius;

  '24': Radius;

  'rounded': Radius;

}

export const radii: Radii = {
  2: 2,
  4: 4,
  8: 8,
  12: 12,
  24: 24,
  rounded: 9999,
};
